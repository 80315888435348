import React from "react";
import "./BannerFlag.css";
import masterCard from "../../assets/img/bandeiras/master-card.webp";
import visa from "../../assets/img/bandeiras/visa.webp";
import elo from "../../assets/img/bandeiras/elo-1.webp";
import hiperCard from "../../assets/img/bandeiras/hiper.webp";
import americanExpress from "../../assets/img/bandeiras/american_express.webp";
import inter from "../../assets/img/bandeiras/inter.webp";
import nubank from "../../assets/img/bandeiras/nubank.webp";
import vr from "../../assets/img/bandeiras/vr.webp";
import alelo from "../../assets/img/bandeiras/alelo.webp";
import applePay from "../../assets/img/bandeiras/applepay.webp";
import googlePay from "../../assets/img/bandeiras/googlepay.webp";
import pix from "../../assets/img/bandeiras/pix.webp";
import qr from "../../assets/img/bandeiras/qrcode.webp";

export default function BannerFlag() {
  return (
    <div className="banner-flag">
      <div className="cs-height_60 cs-height_lg_60"></div>  
      <h2 className="banner-flag-heading" id="planos">Aceite todas as principais formas de pagamento:</h2>
      <div className="cs-height_60 cs-height_lg_60"></div>
      <div className="marquee">
        <div className="marquee-content">
          {/* Bandeiras */}
          <img src={masterCard} alt="Master Card" />
          <img src={visa} alt="Visa" />
          <img src={elo} alt="Elo" />
          <img src={hiperCard} alt="HiperCard" />
          <img src={americanExpress} alt="American Express" />
          <img src={inter} alt="Inter" />
          <img src={nubank} alt="Nubank" />
          <img src={vr} alt="VR" />
          <img src={alelo} alt="Alelo" />
          <img src={applePay} alt="Apple Pay" />
          <img src={googlePay} alt="Google Pay" />
          <img src={pix} alt="Pix" />
          <img src={qr} alt="QR Code" />
          {/* Duplicação para continuidade */}
          <img src={masterCard} alt="Master Card" />
          <img src={visa} alt="Visa" />
          <img src={elo} alt="Elo" />
          <img src={hiperCard} alt="HiperCard" />
          <img src={americanExpress} alt="American Express" />
          <img src={inter} alt="Inter" />
          <img src={nubank} alt="Nubank" />
          <img src={vr} alt="VR" />
          <img src={alelo} alt="Alelo" />
          <img src={applePay} alt="Apple Pay" />
          <img src={googlePay} alt="Google Pay" />
          <img src={pix} alt="Pix" />
          <img src={qr} alt="QR Code" />
          {/* Duplicação para continuidade */}
          <img src={masterCard} alt="Master Card" />
          <img src={visa} alt="Visa" />
          <img src={elo} alt="Elo" />
          <img src={hiperCard} alt="HiperCard" />
          <img src={americanExpress} alt="American Express" />
          <img src={inter} alt="Inter" />
          <img src={nubank} alt="Nubank" />
          <img src={vr} alt="VR" />
          <img src={alelo} alt="Alelo" />
          <img src={applePay} alt="Apple Pay" />
          <img src={googlePay} alt="Google Pay" />
          <img src={pix} alt="Pix" />
          <img src={qr} alt="QR Code" />
        </div>
      </div>
    </div>
  );
}
