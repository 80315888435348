import React, { useState, useEffect } from 'react';

const plans = [
  {
    name: 'Start Link',
    rates: {
      debit: {
        MasterCard: 1.10,
        Visa: 1.10,
        Elo: 1.67,
        Amex: 2.80,
        Hipercard: 2.80,
        Pix: 0.60,
      },
      credit: {
        '1x': {
          MasterCard: 3.26,
          Visa: 3.26,
          Elo: 4.02,
          Amex: 4.03,
          Hipercard: 4.03,
        },
        '2x': {
          MasterCard: 7.78,
          Visa: 7.78,
          Elo: 8.77,
          Amex: 8.77,
          Hipercard: 8.77,
        },
        '3x': {
          MasterCard: 8.43,
          Visa: 8.43,
          Elo: 9.40,
          Amex: 9.40,
          Hipercard: 9.40,
        },
        '4x': {
          MasterCard: 9.48,
          Visa: 9.48,
          Elo: 10.03,
          Amex: 10.02,
          Hipercard: 10.02,
        },
        '5x': {
          MasterCard: 9.75,
          Visa: 9.75,
          Elo: 10.65,
          Amex: 10.67,
          Hipercard: 10.67,
        },
        '6x': {
          MasterCard: 10.38,
          Visa: 10.38,
          Elo: 11.39,
          Amex: 11.36,
          Hipercard: 11.36,
        },
        '7x': {
          MasterCard: 12.27,
          Visa: 12.27,
          Elo: 13.23,
          Amex: 13.22,
          Hipercard: 13.22,
        },
        '8x': {
          MasterCard: 12.88,
          Visa: 12.88,
          Elo: 13.86,
          Amex: 13.87,
          Hipercard: 13.87,
        },
        '9x': {
          MasterCard: 13.60,
          Visa: 13.60,
          Elo: 14.53,
          Amex: 14.53,
          Hipercard: 14.53,
        },
        '10x': {
          MasterCard: 14.26,
          Visa: 14.26,
          Elo: 15.17,
          Amex: 15.17,
          Hipercard: 15.17,
        },
        '11x': {
          MasterCard: 14.87,
          Visa: 14.87,
          Elo: 15.80,
          Amex: 11.82,
          Hipercard: 11.82,
        },
        '12x': {
          MasterCard: 15.59,
          Visa: 15.59,
          Elo: 16.44,
          Amex: 16.44,
          Hipercard: 16.44,
        },
        '13x': {
          MasterCard: 16.20,
          Visa: 16.20,
          Elo: 17.07,
          Amex: 17.09,
          Hipercard: 17.09,
        },
        '14x': {
          MasterCard: 16.86,
          Visa: 16.86,
          Elo: 17.70,
          Amex: 17.70,
          Hipercard: 17.70,
        },
        '15x': {
          MasterCard: 17.50,
          Visa: 17.50,
          Elo: 18.25,
          Amex: 18.25,
          Hipercard: 18.25,
        },
        '16x': {
          MasterCard: 18.12,
          Visa: 18.12,
          Elo: 19.00,
          Amex: 19.02,
          Hipercard: 19.02,
        },
        '17x': {
          MasterCard: 18.80,
          Visa: 18.80,
          Elo: 19.50,
          Amex: 19.50,
          Hipercard: 19.50,
        },
        '18x': {
          MasterCard: 19.37,
          Visa: 19.37,
          Elo: 20.12,
          Amex: 20.12,
          Hipercard: 20.12,
        },
      },
    },
    url: '/planos/start-link',
  },
  {
    name: 'Smart Link',
    rates: {
      debit: {
        MasterCard: 1.14,
        Visa: 1.14,
        Elo: 1.80,
        Amex: 1.79,
        Hipercard: 2.45,
        Pix: 0.50,
      },
      credit: {
        '1x': {
          MasterCard: 3.27,
          Visa: 3.27,
          Elo: 4.20,
          Amex: 4.20,
          Hipercard: 4.26,
        },
        '2x': {
          MasterCard: 5.34,
          Visa: 5.34,
          Elo: 5.37,
          Amex: 5.37,
          Hipercard: 5.15,
        },
        '3x': {
          MasterCard: 6.00,
          Visa: 6.00,
          Elo: 6.07,
          Amex: 6.07,
          Hipercard: 7.50,
        },
        '4x': {
          MasterCard: 6.67,
          Visa: 6.67,
          Elo: 8.27,
          Amex: 8.27,
          Hipercard: 6.57,
        },
        '5x': {
          MasterCard: 7.38,
          Visa: 7.38,
          Elo: 9.29,
          Amex: 9.29,
          Hipercard: 7.27,
        },
        '6x': {
          MasterCard: 8.17,
          Visa: 8.17,
          Elo: 10.20,
          Amex: 10.20,
          Hipercard: 7.96,
        },
        '7x': {
          MasterCard: 8.35,
          Visa: 8.35,
          Elo: 9.40,
          Amex: 9.40,
          Hipercard: 9.05,
        },
        '8x': {
          MasterCard: 9.07,
          Visa: 9.07,
          Elo: 10.11,
          Amex: 10.11,
          Hipercard: 9.77,
        },
        '9x': {
          MasterCard: 9.79,
          Visa: 9.79,
          Elo: 10.85,
          Amex: 10.85,
          Hipercard: 10.47,
        },
        '10x': {
          MasterCard: 10.49,
          Visa: 10.49,
          Elo: 11.55,
          Amex: 11.55,
          Hipercard: 11.16,
        },
        '11x': {
          MasterCard: 10.87,
          Visa: 10.87,
          Elo: 12.26,
          Amex: 12.26,
          Hipercard: 11.87,
        },
        '12x': {
          MasterCard: 11.90,
          Visa: 11.90,
          Elo: 12.35,
          Amex: 12.35,
          Hipercard: 12.56,
        },
        '13x': {
          MasterCard: 12.62,
          Visa: 12.62,
          Elo: 13.66,
          Amex: 13.66,
          Hipercard: 13.31,
        },
        '14x': {
          MasterCard: 13.36,
          Visa: 13.36,
          Elo: 14.37,
          Amex: 14.37,
          Hipercard: 13.97,
        },
        '15x': {
          MasterCard: 14.07,
          Visa: 14.07,
          Elo: 15.05,
          Amex: 15.05,
          Hipercard: 14.70,
        },
        '16x': {
          MasterCard: 14.76,
          Visa: 14.76,
          Elo: 15.76,
          Amex: 15.76,
          Hipercard: 15.39,
        },
        '17x': {
          MasterCard: 15.48,
          Visa: 15.48,
          Elo: 16.48,
          Amex: 16.48,
          Hipercard: 16.10,
        },
        '18x': {
          MasterCard: 16.20,
          Visa: 16.20,
          Elo: 17.18,
          Amex: 17.18,
          Hipercard: 16.83,
        },
      },
    },
    url: 'https://www.cashlinkpay.com.br/smart-link',
  },
  {
    name: 'Master Link',
    rates: {
      debit: {
        MasterCard: 1.22,
        Visa: 1.22,
        Elo: 2.53,
        Amex: 2.53,
        Hipercard: 2.53,
        Pix: 0.50,
      },
      credit: {
        '1x': {
          MasterCard: 3.26,
          Visa: 3.26,
          Elo: 3.27,
          Amex: 4.86,
          Hipercard: 4.86,
        },
        '2x': {
          MasterCard: 5.32,
          Visa: 5.32,
          Elo: 5.32,
          Amex: 6.42,
          Hipercard: 6.42,
        },
        '3x': {
          MasterCard: 6.04,
          Visa: 6.04,
          Elo: 6.80,
          Amex: 7.14,
          Hipercard: 7.14,
        },
        '4x': {
          MasterCard: 6.80,
          Visa: 6.80,
          Elo: 7.87,
          Amex: 7.87,
          Hipercard: 7.87,
        },
        '5x': {
          MasterCard: 7.54,
          Visa: 7.54,
          Elo: 8.57,
          Amex: 8.57,
          Hipercard: 8.57,
        },
        '6x': {
          MasterCard: 8.20,
          Visa: 8.20,
          Elo: 9.30,
          Amex: 9.30,
          Hipercard: 9.30,
        },
        '7x': {
          MasterCard: 8.90,
          Visa: 8.90,
          Elo: 10.00,
          Amex: 10.00,
          Hipercard: 10.00,
        },
        '8x': {
          MasterCard: 9.60,
          Visa: 9.60,
          Elo: 10.67,
          Amex: 10.67,
          Hipercard: 10.67,
        },
        '9x': {
          MasterCard: 10.30,
          Visa: 10.30,
          Elo: 11.37,
          Amex: 11.37,
          Hipercard: 11.37,
        },
        '10x': {
          MasterCard: 11.00,
          Visa: 11.00,
          Elo: 12.02,
          Amex: 12.02,
          Hipercard: 12.02,
        },
        '11x': {
          MasterCard: 11.65,
          Visa: 11.65,
          Elo: 12.70,
          Amex: 12.70,
          Hipercard: 12.70,
        },
        '12x': {
          MasterCard: 12.33,
          Visa: 12.33,
          Elo: 13.37,
          Amex: 13.37,
          Hipercard: 13.37,
        },
        '13x': {
          MasterCard: 13.40,
          Visa: 13.40,
          Elo: 14.81,
          Amex: 14.81,
          Hipercard: 14.81,
        },
        '14x': {
          MasterCard: 14.04,
          Visa: 14.04,
          Elo: 15.45,
          Amex: 15.45,
          Hipercard: 15.45,
        },
        '15x': {
          MasterCard: 14.68,
          Visa: 14.68,
          Elo: 16.09,
          Amex: 16.09,
          Hipercard: 16.09,
        },
        '16x': {
          MasterCard: 15.32,
          Visa: 15.32,
          Elo: 16.73,
          Amex: 16.73,
          Hipercard: 16.73,
        },
        '17x': {
          MasterCard: 15.97,
          Visa: 15.97,
          Elo: 17.38,
          Amex: 17.38,
          Hipercard: 17.38,
        },
        '18x': {
          MasterCard: 16.63,
          Visa: 16.63,
          Elo: 18.04,
          Amex: 18.04,
          Hipercard: 18.04,
        },
      },
    },
    url: 'https://www.cashlinkpay.com.br/smart-link',
  },
  {
    name: 'Ultra Link',
    rates: {
      debit: {
        MasterCard: 1.22,
        Visa: 1.22,
        Elo: 2.53,
        Amex: 2.53,
        Hipercard: 2.53,
        Pix: 0.50,
      },
      credit: {
        '1x': {
          MasterCard: 3.26,
          Visa: 3.26,
          Elo: 3.27,
          Amex: 4.86,
          Hipercard: 4.86,
        },
        '2x': {
          MasterCard: 5.32,
          Visa: 5.32,
          Elo: 5.32,
          Amex: 6.42,
          Hipercard: 6.42,
        },
        '3x': {
          MasterCard: 6.04,
          Visa: 6.04,
          Elo: 6.80,
          Amex: 7.14,
          Hipercard: 7.14,
        },
        '4x': {
          MasterCard: 6.80,
          Visa: 6.80,
          Elo: 7.87,
          Amex: 7.87,
          Hipercard: 7.87,
        },
        '5x': {
          MasterCard: 7.54,
          Visa: 7.54,
          Elo: 8.57,
          Amex: 8.57,
          Hipercard: 8.57,
        },
        '6x': {
          MasterCard: 8.20,
          Visa: 8.20,
          Elo: 9.30,
          Amex: 9.30,
          Hipercard: 9.30,
        },
        '7x': {
          MasterCard: 8.90,
          Visa: 8.90,
          Elo: 10.00,
          Amex: 10.00,
          Hipercard: 10.00,
        },
        '8x': {
          MasterCard: 9.60,
          Visa: 9.60,
          Elo: 10.67,
          Amex: 10.67,
          Hipercard: 10.67,
        },
        '9x': {
          MasterCard: 10.30,
          Visa: 10.30,
          Elo: 11.37,
          Amex: 11.37,
          Hipercard: 11.37,
        },
        '10x': {
          MasterCard: 11.00,
          Visa: 11.00,
          Elo: 12.02,
          Amex: 12.02,
          Hipercard: 12.02,
        },
        '11x': {
          MasterCard: 11.65,
          Visa: 11.65,
          Elo: 12.70,
          Amex: 12.70,
          Hipercard: 12.70,
        },
        '12x': {
          MasterCard: 12.33,
          Visa: 12.33,
          Elo: 13.37,
          Amex: 13.37,
          Hipercard: 13.37,
        },
        '13x': {
          MasterCard: 13.40,
          Visa: 13.40,
          Elo: 14.81,
          Amex: 14.81,
          Hipercard: 14.81,
        },
        '14x': {
          MasterCard: 14.04,
          Visa: 14.04,
          Elo: 15.45,
          Amex: 15.45,
          Hipercard: 15.45,
        },
        '15x': {
          MasterCard: 14.68,
          Visa: 14.68,
          Elo: 16.09,
          Amex: 16.09,
          Hipercard: 16.09,
        },
        '16x': {
          MasterCard: 15.32,
          Visa: 15.32,
          Elo: 16.73,
          Amex: 16.73,
          Hipercard: 16.73,
        },
        '17x': {
          MasterCard: 15.97,
          Visa: 15.97,
          Elo: 17.38,
          Amex: 17.38,
          Hipercard: 17.38,
        },
        '18x': {
          MasterCard: 16.63,
          Visa: 16.63,
          Elo: 18.04,
          Amex: 18.04,
          Hipercard: 18.04,
        },
      },
    },
    url: 'https://www.cashlinkpay.com.br/smart-link',
  },
];

const cardBrands = ['MasterCard', 'Visa', 'Elo', 'Amex', 'Hipercard', 'Pix'];
const saleTypes = [
  'Débito',
  'Crédito 1x',
  'Crédito 2x',
  'Crédito 3x',
  'Crédito 4x',
  'Crédito 5x',
  'Crédito 6x',
  'Crédito 7x',
  'Crédito 8x',
  'Crédito 9x',
  'Crédito 10x',
  'Crédito 11x',
  'Crédito 12x',
  'Crédito 13x',
  'Crédito 14x',
  'Crédito 15x',
  'Crédito 16x',
  'Crédito 17x',
  'Crédito 18x',
];

const Simulator = () => {
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [selectedBrand, setSelectedBrand] = useState(cardBrands[0]);
  const [selectedSaleType, setSelectedSaleType] = useState(saleTypes[0]); // Inicial como "Débito"
  const [saleValue, setSaleValue] = useState(1000); // Valor inicial como número

  // Atualiza o Tipo de Venda ao alterar a Bandeira para PIX
  useEffect(() => {
    if (selectedBrand === "Pix") {
      setSelectedSaleType("Débito"); // Fixar para "Débito" ou outro padrão
    }
  }, [selectedBrand]);

  const getRate = () => {
    const isDebit = selectedSaleType === "Débito";
    let rate;
    if (isDebit) {
      rate = selectedPlan.rates.debit[selectedBrand];
    } else {
      const installments = selectedSaleType.match(/\d+/)[0] + "x";
      rate = selectedPlan.rates.credit[installments][selectedBrand];
    }
    return rate || 0; // Retorna o valor como número, garantindo um fallback para 0
  };

  const calculateReceivedAmount = () => {
    const rate = getRate();
    const amount = saleValue - (saleValue * rate) / 100;
    return amount; // Mantém o número para formatação posterior
  };

  const calculateSavings = () => {
    const rate = getRate();
    const savingsRate = rate - 1 > 0 ? rate + 1.5 : 0; // Calcula a taxa de economia
    const savingsAmount = (saleValue * savingsRate) / 100; // Aplica a economia sobre o valor da venda
    return savingsAmount;
  };

  // Formata o número como moeda brasileira (R$)
  const formatToCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  // Lida com a entrada do usuário no campo de texto
  const handleSaleValueChange = (e) => {
    const inputValue = e.target.value.replace(/[^\d]/g, ""); // Remove tudo que não for número
    const numericValue = parseFloat(inputValue) / 100 || 0; // Divide por 100 para considerar centavos
    setSaleValue(numericValue); // Armazena o valor como número
  };

  const handleButtonClick = () => {
    const url = selectedPlan.url;
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      window.location.pathname = url;
    }
  };

  const isPix = selectedBrand === "Pix"; // Verifica se é PIX

  return (    
    <div className="simulator" id="simulador">
      <div className="cs-height_60 cs-height_lg_60" ></div>
      <h2>Simule as taxas das suas vendas</h2>
      <div className="simulator-form">
        {/* Seleção de Plano */}
        <div className="form-group">
          <label>Selecione seu Plano CashLink:</label>
          <div className="plan-options">
            {plans.map((plan, index) => (
              <button
                key={index}
                className={`plan-button ${selectedPlan.name === plan.name ? "active" : ""}`}
                onClick={() => setSelectedPlan(plan)}
              >
                {plan.name}
              </button>
            ))}
          </div>
        </div>

        {/* Seleção de Bandeira */}
        <div className="form-group">
          <label>Bandeiras:</label>
          <select
            value={selectedBrand}
            onChange={(e) => setSelectedBrand(e.target.value)}
          >
            {cardBrands.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </div>

        {/* Tipo de Venda (ocultado para PIX) */}
        {!isPix && (
          <div className="form-group">
            <label>Tipo da Venda:</label>
            <select
              value={selectedSaleType}
              onChange={(e) => setSelectedSaleType(e.target.value)}
            >
              {saleTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Valor da Venda */}
        <div className="form-group">
          <label>Valor da Venda:</label>
          <input
            type="text"
            value={formatToCurrency(saleValue)} // Formata para moeda brasileira
            onChange={handleSaleValueChange} // Atualiza o valor enquanto digita
          />
        </div>

        {/* Resultado */}
        <div className="result-grid">
          {/* Você Recebe */}
          <div className="grid-item">
            <p>
              Você recebe:{" "}
              <strong>{formatToCurrency(calculateReceivedAmount())}</strong>
            </p>
          </div>
          {/* Taxa */}
          <div className="grid-item">
          <p>
            Taxa: <strong>{getRate().toFixed(2)}%</strong> {/* Formata apenas na exibição */}
          </p>
          </div>
        </div>

        {/* Exibição condicional de economia */}
        {!isPix && (
          <div className="savings-grid">
            <p>
              Você economiza até{" "}
              <strong className="text-newTon-700">
                {formatToCurrency(calculateSavings())}
              </strong>{" "}
              em relação aos concorrentes
            </p>
          </div>
        )}
      </div>
      <div className="tips-div">
        <h5>Você recebe em 1 dia útil direto na sua conta CashLink</h5>
      </div>

      {/* Botão Escolher Plano */}
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button className="cs-btn" onClick={handleButtonClick}>
          <span>Escolher Plano {selectedPlan.name}</span>
        </button>
      </div>
      <div className="cs-height_60 cs-height_lg_60"></div>
    </div>
  );
};

export default Simulator;
