import React, { useState } from "react";

export default function Maquininhas() {
  const [selectedPlan, setSelectedPlan] = useState("start");

  const machines = {
    start: [
      {
        name: "Point Link",
        priceInstallment: "R$33,08",
        priceView: "R$497,00",
        oldPrice: "R$699,00",
        discount: "28% OFF",
        imgSrc: "../../../img/cashlink/02.png",
        features: [
          "Comprovante Impresso ou por SMS",
          "Com Chip 4G Grátis + WIFI",
          "Aceite PIX QR Code na Maquininha",
          "Receba por Aproximação (NFC)",
          "Tela Touchscreen",
        ],
      },
      {
        name: "Smart Link",
        priceInstallment: "R$41,42",
        priceView: "R$597,00",
        oldPrice: "R$799,00",
        discount: "25% OFF",
        imgSrc: "../../../img/cashlink/11.png",
        features: [
          "Comprovante Impresso ou por SMS",
          "Com Chip 4G Grátis + WIFI",
          "Aceite PIX QR Code na Maquininha",
          "Receba por Aproximação (NFC)",
          "Sistema Android + Tela Touchscreen",
          "Gestão de produtos/estoque (Confira disponibilidade)",
        ],
      },
    ],
    master: [
      {
        name: "Point Link",
        priceInstallment: "R$33,08",
        priceView: "R$349,50",
        oldPrice: "R$699,00",
        discount: "50% OFF",
        imgSrc: "../../../img/cashlink/02.png",
        features: [
          "Comprovante Impresso ou por SMS",
          "Com Chip 4G Grátis + WIFI",
          "Aceite PIX QR Code na Maquininha",
          "Receba por Aproximação (NFC)",
          "Tela Touchscreen",
        ],
      },
      {
        name: "Smart Link",
        priceInstallment: "R$41,42",
        priceView: "R$479,00",
        oldPrice: "R$799,00",
        discount: "40% OFF",
        imgSrc: "../../../img/cashlink/11.png",
        features: [
          "Comprovante Impresso ou por SMS",
          "Com Chip 4G Grátis + WIFI",
          "Aceite PIX QR Code na Maquininha",
          "Receba por Aproximação (NFC)",
          "Sistema Android + Tela Touchscreen",
          "Gestão de produtos/estoque (Confira disponibilidade)",
        ],
      },
    ],
  };

  return (
    <section
      className="cs-bg"
      data-src="../../../img/feature_bg.svg"
      id="maquininhas"
    >
      <div className="cs-height_95 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div
            className="cs-section_subtitle wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            COMPRE JÁ SUA MAQUININHA
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <h3 className="cs-section_title">Escolha sua Maquininha</h3>
          <h6 className="cs-section_title">Planos:</h6>
        </div>

        {/* Toggle Buttons */}
        <div className="cs-height_30"></div>
        <div className="toggle-container">
          <button
            className={`toggle-btn ${selectedPlan === "start" ? "active" : ""}`}
            onClick={() => setSelectedPlan("start")}
          >
            Start Link - Super Link
          </button>
          <button
            className={`toggle-btn ${selectedPlan === "master" ? "active" : ""}`}
            onClick={() => setSelectedPlan("master")}
          >
            Master Link - Ultra Link
          </button>
        </div>

        <div className="cs-height_50 cs-height_lg_40"></div>
        <div className="row">
          {machines[selectedPlan].map((machine, index) => (
            <div className="col-lg-6" key={index}>
              <div className="cs-pricing_table cs-style1">
                <div className="cs-pricing_head">
                  <div className="cs-pricing_heading">
                    <div className="cs-center pos-img-plans">
                      <img src={machine.imgSrc} alt={machine.name} />
                    </div>
                    <h2 className="cs-pricing_title cs-m0">{machine.name}</h2>
                  </div>
                  <div className="cs-price cs-primary_font">
                    <span>12x de</span>
                    <b className="cs-accent_color">{machine.priceInstallment}</b>
                  </div>
                  <div className="cs-pricing_lable">
                    ou à vista por <span>{machine.priceView}</span>{" "}
                    <s>{machine.oldPrice}</s>
                  </div>
                  <span className="frete-gratis">🚚 FRETE GRÁTIS 🚛</span>
                  <br />
                  <span className="desconto">🔥 {machine.discount} 🔥</span>
                </div>
                <div className="cs-pricing_body">
                  <ul className="cs-mp0 cs-pricing_feature">
                    {machine.features.map((feature, idx) => (
                      <li key={idx}>
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.18215 0C3.771 0 0.18219 3.58872 0.18219 7.99996C0.18219 12.4112 3.771 16 8.18215 16C12.5933 16 16.1821 12.4112 16.1821 7.99996C16.1821 3.58872 12.5934 0 8.18215 0ZM12.7759 6.6487L7.7499 11.6747C7.53619 11.8884 7.25212 12.006 6.94993 12.006C6.64774 12.006 6.36366 11.8884 6.14996 11.6747L3.58843 9.11314C3.37473 8.89944 3.25702 8.61536 3.25702 8.31317C3.25702 8.01089 3.37473 7.72682 3.58843 7.51311C3.80205 7.29941 4.08613 7.18171 4.3884 7.18171C4.69059 7.18171 4.97475 7.29941 5.18837 7.5132L6.94984 9.27459L11.1758 5.04867C11.3895 4.83497 11.6735 4.71735 11.9757 4.71735C12.2779 4.71735 12.562 4.83497 12.7757 5.04867C13.217 5.48994 13.217 6.2076 12.7759 6.6487Z"
                            fill="#47C4C0"
                          />
                        </svg>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="cs-pricing_btn">
                    <a href="/" className="cs-btn cs-size_md">
                      <span>Pedir {machine.name}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
          ))}
        </div>
        <div className="cs-height_75 cs-height_lg_45"></div>
      </div>
    </section>
  );
}
