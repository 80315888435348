import React from "react";

export default function Blog() {

  return (
    <div className="cs-modal" data-modal="details1">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_60 cs-height_lg_60"></div>
          <div className="cs-blog_details">
            <h2>Pagamento por Aproximação: Uma Vantagem para o Seu Negócio</h2>
            <h6>Descubra tudo sobre o pagamento NFC ou por Aproximação: tecnologia, segurança, praticidade <br></br> e como adotar essa inovação no seu dia a dia.</h6>
            <img
              src="../../../img/NFC.jpg"
              alt="Pagamento por Aproximação"
              className="cs-radius_10 w-100"
            />
            <h3>O que é o pagamento por aproximação?</h3>
            <p>
              O pagamento por aproximação é uma tecnologia que utiliza NFC (Near Field Communication) para 
              realizar transações financeiras sem a necessidade de inserir cartões em máquinas. Basta aproximar
              o cartão ou dispositivo habilitado da maquininha, e a transação é concluída de forma segura e 
              prática.
            </p>

            <h3>Por que implementar no seu negócio?</h3>
            <p>
              Essa modalidade de pagamento vem ganhando popularidade devido à sua rapidez e conveniência. 
              Empresas que adotam essa tecnologia podem oferecer uma experiência de compra mais ágil, aumentando
              a satisfação do cliente e reduzindo filas.
            </p>

            <h3>Benefícios para sua empresa</h3>
            <ul>
              <li>Redução do tempo de espera no caixa.</li>
              <li>Maior segurança contra fraudes, já que os dados do cartão não são fisicamente expostos.</li>
              <li>Praticidade para clientes, especialmente para transações de pequeno valor.</li>
              <li>Modernização da imagem da sua marca, demonstrando inovação.</li>
            </ul>

            <h3>Como começar?</h3>
            <p>
              Para implementar o pagamento por aproximação, é necessário garantir que suas maquininhas sejam compatíveis 
              com a tecnologia NFC. Além disso, ofereça treinamento para sua equipe e comunique os clientes sobre essa 
              nova facilidade.
            </p>

            <blockquote>
              <p>
                "Adotar o pagamento por aproximação transformou a experiência de compra dos nossos clientes. 
                Agora, tudo é mais rápido e seguro."
              </p>
              <small>Joana Silva, Empresária</small>
            </blockquote>
            <div className="cs-height_60 cs-height_lg_60"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
