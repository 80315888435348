import React from "react";
import Preloader from "../../component/Preloader/Preloader";
import Hero from "../../component/Hero/Hero";
import FunFact from "../../component/FunFact/FunFact";
import AllFeature from "../../component/AllFeature/AllFeature";
import Banner from "../../component/Banner01/Banner";
import Testimonials from "../../component/Testimonials/Testimonials";
import Simulador from "../../component/Simulador/Simulador";
import Faq from "../../component/Faq/Faq";
import Post from "../../component/Post/Post";
import Blog from "../../component/Model/Blog04";
import Blog01 from "../../component/Model/Blog01";
import Blog02 from "../../component/Model/Blog02";
import Blog03 from "../../component/Model/Blog03";
import Blog04 from "../../component/Model/Blog";
import Planos from "./Planos/Planos";
import About from "./About/About";
import Maquininhas from "./Maquininhas/Maquininhas";
import BannerFlag from "../../component/Layout/BannerFlag";
import BannerHeader from "../../component/Layout/BannerHeader";

export default function Home() {
  return (
    <div>
      <Preloader></Preloader>
      {/* <!-- Start Hero --> */}
      <Hero></Hero>
      {/* <!-- End Hero --> */}
      <BannerFlag/>
      {/* <!-- Start Main Feature --> */}
      <Planos />
      {/* <!-- End Main Feature --> */}
      {/* <!-- Start About --> */}
      <About />
      {/* <!-- End About --> */}
      {/* <!-- Start Price Section --> */}
      <BannerHeader />
      <Maquininhas />
      <BannerHeader />
      {/* <!-- End Price Section --> */}
      
      {/* <!-- Start All Feature --> */}
      <AllFeature />
      {/* <!-- End All Feature --> */}
      {/* <!-- Start Fun Fact --> */}
      <FunFact />
      {/* <!-- End Fun Fact --> */}
      <Simulador/>
      {/* <!-- Start Retail Stores --> */}
      
      {/* <!-- End Retail Stores --> */}
      
      {/* <!-- End Testimonials Section --> */}
      <Testimonials />
      {/* <!-- End Testimonials Stores --> */}
      {/* <!-- End Client Section --> */}
      <Banner />
      {/* <!-- End Client Stores --> */}
      {/* <!-- Start FAQ --> */}
      <Faq />
      {/* <!-- End FAQ --> */}
      {/* <!-- End Post Section --> */}
      <Post />
      {/* <!-- End Post Stores --> */}
      {/* <!-- Start Contact Section --> */}
      {/* <!-- End Contact Section --> */}
      {/* <!-- Start Blog Details --> */}
      <Blog />
      <Blog01 />
      <Blog02 />
      <Blog03 />
      <Blog04/>
      {/* <!-- end Blog Details --> */}
    </div>
  );
}
