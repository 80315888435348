import React from "react";

export default function Blog() {
  return (
    <div className="cs-modal" data-modal="details3">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_60 cs-height_lg_60"></div>
          <div className="cs-blog_details">
            <h2>Como a Taxa Selic pode impactar o seu negócio?</h2>
            <h6>
              Entenda como a Taxa Selic influencia as finanças do seu negócio e como a CashLink pode ajudar a mitigar esses impactos.
            </h6>
            <img
              src="../../../img/selic1.jpg"
              alt="Taxa Selic e Negócios"
              className="cs-radius_10 w-100"
            />
            <h3>Taxa Selic: O que é?</h3>
            <p>
              A Taxa Selic, sigla para Sistema Especial de Liquidação e Custódia, é a taxa básica de juros da economia brasileira. Ela serve como referência para empréstimos, financiamentos e outras operações de crédito. A cada 45 dias, o Copom (Comitê de Política Monetária do Banco Central) se reúne para decidir se ela será mantida, aumentada ou reduzida.
            </p>

            <h3>Qual é o papel da Selic?</h3>
            <p>
              A principal função da Selic é controlar a inflação. Quando ela sobe, os juros de crédito aumentam, desacelerando o consumo e ajudando a conter a inflação. Por outro lado, quando a Selic é reduzida, o crédito fica mais acessível, estimulando o consumo e aquecendo a economia.
            </p>

            <h3>Como a Selic afeta as taxas da maquininha?</h3>
            <ul>
              <li>Quando a Selic sobe, as taxas de crédito, incluindo as de maquininhas, tendem a aumentar.</li>
              <li>Quando a Selic cai, as taxas de crédito podem ser reduzidas, beneficiando empresas e consumidores.</li>
            </ul>

            <h3>CashLink: sua melhor escolha, independente da Selic</h3>
            <p>
              Na CashLink, entendemos que taxas altas podem ser um grande desafio para os negócios. Por isso, oferecemos as melhores condições do mercado, mesmo diante de mudanças na Selic. Com a CashLink, você tem:
            </p>
            <ul>
              <li>As menores taxas para vendas parceladas.</li>
              <li>Isenção de aluguel e faturamento mínimo.</li>
              <li>Redução de taxas em até 30 dias.</li>
              <li>Transparência e confiabilidade, independente da situação econômica.</li>
            </ul>

            <blockquote>
              <p>
                "a CashLink me ajudou a economizar e manter meu negócio competitivo, mesmo em tempos de alta da Selic."
              </p>
              <small>Carla Mendes, Empresária</small>
            </blockquote>

            <p>
              Quer saber mais? Nossa equipe está disponível para tirar todas as suas dúvidas! 
              <a
                href="https://wa.me/5561982217107?text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20algumas%20d%C3%BAvidas."
                target="_blank"
                rel="noopener noreferrer"
              >
                <br></br>
                Clique aqui para conversar conosco pelo WhatsApp.
              </a>
            </p>
            <div className="cs-height_60 cs-height_lg_60"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
