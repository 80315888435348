import React from "react";

const posts = [
  {
    id: 0,
    image: "../../../img/parceria.jpg",
    title: "Parceria CashLink e Player4 Studios: Benefícios Exclusivos para Clientes",
    modal: "details",
  },
  {
    id: 1,
    image: "../../../img/nfc01.jpg",
    title: "Pagamento por Aproximação: Uma Vantagem para o Seu Negócio",
    modal: "details1",
  },
  {
    id: 2,
    image: "../../../img/economia.jpg",
    title: "Transforme Sua Gestão de Pagamentos",
    modal: "details2",
  },
  {
    id: 3,
    image: "../../../img/selic.jpg",
    title: "Como a Taxa Selic pode impactar o seu negócio?",
    modal: "details3",
  },
  {
    id: 4,
    image: "../../../img/alavancar1.jpg",
    title: "Dicas para Alavancar as Vendas do Seu Negócio",
    modal: "details4",
  },
];

export default function Post() {
  // Função para abrir o modal com base no atributo `data-modal`
  const uniquePosts = React.useMemo(() => posts.slice(0, 5), []);

  const openModal = (modalId) => {
    const modalElement = document.querySelector(`[data-modal-id="${modalId}"]`);
    if (modalElement) {
      modalElement.classList.add("open");
    }
  };

  return (
    <section className="cs-bg" data-src="../../../img/feature_bg.svg" id="news">
      <div className="container">
        <div className="cs-seciton_heading cs-style1 text-center">
          <div
            className="cs-section_subtitle wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.4s"
          >
            Artigos e Dicas
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <h3 className="cs-section_title">Últimos Artigos e Dicas</h3>
        </div>
        <div className="cs-height_50 cs-height_lg_40"></div>
        <div className="cs-slider cs-style1 cs-gap-24">
          <div
            className="cs-slider_container"
            data-autoplay="0"
            data-loop="1"
            data-speed="600"
            data-fade-slide="0"
            data-slides-per-view="responsive"
            data-xs-slides="1"
            data-sm-slides="2"
            data-md-slides="3"
            data-lg-slides="4"
            data-add-slides="4"
          >
            <div className="cs-slider_wrapper">
              {uniquePosts.map((post) => (
                <div className="cs-slide" key={post.id}>
                  <div className="cs-post cs-style1">
                    <div
                      className="cs-post_thumb cs-modal_btn"
                      data-modal={post.modal}
                      onClick={() => openModal(post.modal)}
                    >
                      <div
                        className="cs-post_thumb_in cs-bg"
                        data-src={post.image}
                      ></div>
                    </div>
                    <div className="cs-post_info">
                      <h2
                        className="cs-post_title cs-modal_btn"
                        data-modal={post.modal}
                        onClick={() => openModal(post.modal)}
                      >
                        {post.title}
                      </h2>
                      <span
                        className="cs-text_btn cs-modal_btn"
                        data-modal={post.modal}
                        onClick={() => openModal(post.modal)}
                      >
                        <span>Saiba Mais</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="currentColor"
                            d="M13.3 17.275q-.3-.3-.288-.725q.013-.425.313-.725L16.15 13H5q-.425 0-.713-.288Q4 12.425 4 12t.287-.713Q4.575 11 5 11h11.15L13.3 8.15q-.3-.3-.3-.713q0-.412.3-.712t.713-.3q.412 0 .712.3L19.3 11.3q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.687.275q-.413 0-.713-.3Z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="cs-pagination cs-style1"></div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
    </section>
  );
}
