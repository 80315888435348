import React from "react";

export default function Blog() {
  return (
    <div className="cs-modal" data-modal="details4">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_60 cs-height_lg_60"></div>
          <div className="cs-blog_details">
            <h2>Dicas para Alavancar as Vendas do Seu Negócio</h2>
            <h6>
              Descubra como estratégias simples e práticas podem impulsionar suas vendas e fidelizar seus clientes!
            </h6>
            <img
              src="../../../img/alavancar.jpg"
              alt="Alavanque suas vendas"
              className="cs-radius_10 w-100"
            />
            <h3>1. Crie Landing Pages Direcionadas</h3>
            <p>
              Ter uma página específica para cada produto ou serviço é essencial para converter clientes. Uma 
              Landing Page bem feita pode destacar as vantagens do que você oferece e direcionar o cliente 
              diretamente para a compra, aumentando as chances de conversão.
            </p>
            <ul>
              <li>Seja objetivo: destaque os benefícios principais.</li>
              <li>Adicione um call-to-action claro, como "Compre Agora".</li>
              <li>Use imagens e depoimentos reais para gerar confiança.</li>
            </ul>

            <h3>2. Atendimento Automatizado com WhatsApp</h3>
            <p>
              Responder rapidamente é essencial para não perder vendas. Automatize parte do atendimento no WhatsApp 
              para fornecer informações e atender seus clientes 24/7. Ferramentas como chatbots podem cuidar das perguntas 
              frequentes, enquanto você se concentra em negociações mais complexas.
            </p>
            <ul>
              <li>Configure mensagens automáticas de boas-vindas e suporte.</li>
              <li>Integre um CRM para acompanhar as conversas e históricos.</li>
              <li>Ofereça facilidades como links diretos para pagamento.</li>
            </ul>

            <h3>3. Seja Ativo nas Redes Sociais</h3>
            <p>
              Esteja onde seu público está! Redes sociais são ótimas para criar engajamento e atrair novos clientes. 
              Publique conteúdos relevantes, compartilhe promoções e mostre como seu produto ou serviço faz a diferença.
            </p>
            <ul>
              <li>Crie posts que resolvam dores do seu público.</li>
              <li>Utilize anúncios pagos para alcançar mais pessoas.</li>
              <li>Interaja com seus seguidores para construir confiança.</li>
            </ul>

            <h3>4. Use a Maquininha CashLink</h3>
            <p>
              Oferecer flexibilidade no pagamento é um diferencial para atrair e fidelizar clientes. Com a maquininha 
              CashLink você garante as menores taxas do mercado, suporte rápido e integração com os principais métodos de pagamento, como cartões e Pix.
            </p>
            <ul>
              <li>Baixas taxas em vendas parceladas.</li>
              <li>Agilidade na aprovação e recebimento.</li>
              <li>Suporte dedicado para todas as suas dúvidas.</li>
            </ul>

            <blockquote>
              <p>
                "Depois que começamos a usar a maquininha CashLink e implementamos atendimento automatizado no WhatsApp, 
                nossas vendas dispararam. Recomendo a todos!"
              </p>
              <small>Paula Martins, Empreendedora</small>
            </blockquote>

            <h3>Pronto para Alavancar Suas Vendas?</h3>
            <p>
              Comece agora mesmo! Implemente essas dicas no seu negócio e veja a diferença. Quer saber mais sobre a 
              maquininha CashLink? Entre em contato com nossa equipe e tire suas dúvidas.
            </p>
            <p>
              <a
                href="https://wa.me/5561982217107?text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20as%20máquinas%20CashLink."
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui para falar conosco pelo WhatsApp
              </a>
            </p>
            <div className="cs-height_60 cs-height_lg_60"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
