import React from "react";
import "./BannerHeader.css";

export default function BannerHeader() {
  return (
    <div className="banner-header">
      <div className="marquee">
        <div className="marquee-content">
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
          <span >🚚 FRETE GRÁTIS 🚛</span>
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
          <span >🚚 FRETE GRÁTIS 🚛</span>
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
          <span >🚚 FRETE GRÁTIS 🚛</span>
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
        </div>
        <div className="marquee-content">
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
          <span >🚚 FRETE GRÁTIS 🚛</span>
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
          <span >🚚 FRETE GRÁTIS 🚛</span>
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
          <span >🚚 FRETE GRÁTIS 🚛</span>
          <span>🔥 MÁQUINAS COM ATÉ 50% DE DESCONTO 🔥</span>
        </div>
      </div>
    </div>
  );
}
