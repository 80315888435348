import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import WppWidget from "./WppWidget";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <WppWidget/>
      
    </>
  );
}
