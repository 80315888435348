import React from "react";

export default function FunFact() {
  return (
    <div>
      <br />
      <br />
      <div className="cs-funfact_1_wrap2">
        <p className="cs-funfact-title">
          Já viu que a CashLink é a{" "}
          <span className="text-newTon-600">
            parceira ideal pra quem tá na missão
          </span>
          , né? E aí, bora vender mais?
        </p>

        <div className="cs-funfact-buttons">
          <a
            target="_self"
            className="cs-btn-primary"
            href="/#planos"
          >
            Escolha seu plano
          </a>
          <a
            target="_self"
            className="cs-btn-secondary"
            href="https://conta.cashlinkpay.com.br/register"
          >
            Abra sua Conta CashLink
          </a>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
}
