import { createBrowserRouter } from "react-router-dom";
import Home from "../Pages/Home";
import Planos from "../Pages/Planos";
import SobreNos from "../Pages/SobreNos";
import Layout from "../component/Layout/Layout";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/planos", // Rota para Planos
        element: <Planos />, // Componente correspondente
      },
      {
        path: "/sobrenos", // Rota para Planos
        element: <SobreNos />, // Componente correspondente
      },
    ],
  },
]);
