import React from "react";

export default function FunFact() {
  return (
    <div className="container-fluid">
      <div className="cs-height_60 cs-height_lg_60"></div>
      <div className="cs-funfact_1_wrap cs-type1">
        <div className="cs-funfact cs-style1">
          <div className="cs-funfact_icon cs-center">
            <img src="../../../img/icons/funfact_icon_1.svg" alt="Icon" />
          </div>
          <div className="cs-funfact_right">
            <div className="cs-funfact_number cs-primary_font">
              <span className="odometer"></span>CPF ou CNPJ
            </div>
            <h2 className="cs-funfact_title">Para atender a necessidade<br></br> do seu negócio.</h2>
          </div>
        </div>
        <div className="cs-funfact cs-style1">
          <div className="cs-funfact_icon cs-center">
            <img src="../../../img/icons/funfact_icon_2.svg" alt="Icon" />
          </div>
          <div className="cs-funfact_right">
            <div className="cs-funfact_number cs-primary_font">
              <span className="odometer"></span>Suporte
            </div>
            <h2 className="cs-funfact_title">especializado<br></br> e ágil.</h2>
          </div>
        </div>
        <div className="cs-funfact cs-style1">
          <div className="cs-funfact_icon cs-center">
            <img src="../../../img/icons/funfact_icon_3.svg" alt="Icon" />
          </div>
          <div className="cs-funfact_right">
            <div className="cs-funfact_number cs-primary_font">
              <span className="odometer"></span>Quem tem, indica
            </div>
            <h2 className="cs-funfact_title">Recomendações por onde passa</h2>
          </div>
        </div>
       
      </div> 
      <div className="cs-height_60 cs-height_lg_60"></div>
      <div className="cs-height_60 cs-height_lg_60"></div>
    </div>
  );
}
