import React from "react";

export default function AllFeature() {
  return (
    <section className="cs-bg" data-src="../../../img/feature_bg.svg">
      <div className="cs-height_135 cs-height_lg_0"></div>
      <div id="feature">
        <div className="cs-height_95 cs-height_lg_70"></div>
        <div className="container">
          <div className="cs-seciton_heading cs-style1 text-center">
            <div
              className="cs-section_subtitle wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >

            </div>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <h3 className="cs-section_title">Tudo que o seu negócio precisa pra vender, gerir e girar</h3>
          </div>
          <div className="cs-height_50 cs-height_lg_40"></div>
          <div className="row">
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_5.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Justo pro seu bolso</h3>
                  <div className="cs-iconbox_subtitle">
                    Quanto mais seu negócio vende, menos taxa você paga.
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_6.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Tudo num só App</h3>
                  <div className="cs-iconbox_subtitle">
                    Venda de várias formas, pague contas e faça toda sua gestão.
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_7.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Sistemas Personalizados</h3>
                  <div className="cs-iconbox_subtitle">
                    Parcerias exclusivas com soluções personalizadas para alavancar seu negócio.
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_8.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Venda mais fácil via Boleto</h3>
                  <div className="cs-iconbox_subtitle">
                    Gere boletos de pagamento e receba de forma simples e segura no aplicativo CashLink.
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_9.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Venda Online</h3>
                  <div className="cs-iconbox_subtitle">
                    Venda Online com Links de Pagamento, API e tenha suporte com nossos parceiros para digitalizar sua empresa, consulte o regulamento.
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
            <div className="col-xl-4 col-md-6">
              <div className="cs-iconbox cs-style1 cs-type1">
                <div className="cs-iconbox_icon cs-center">
                  <img src="../../../img/icons/icon_box_10.svg" alt="Icon" />
                </div>
                <div className="cs-iconbox_in">
                  <h3 className="cs-iconbox_title">Não precisa de Banco</h3>
                  <div className="cs-iconbox_subtitle">                    
                    Com a conta CashLink, você gerencia tudo em um só lugar: receba dinheiro, faça cobranças e realize pagamentos com praticidade.
                    E você não paga para abrir conta.
                  </div>
                </div>
              </div>
              <div className="cs-height_25 cs-height_lg_25"></div>
            </div>
          </div>
        </div>
        <div className="cs-height_75 cs-height_lg_45"></div>
      </div>
    </section>
  );
}
