import React from "react";

export default function Blog() {

  return (
    <div className="cs-modal" data-modal="details2">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_60 cs-height_lg_60"></div>
          <div className="cs-blog_details">
            <h2>Transforme Sua Gestão de Pagamentos</h2>
            <h6>Saiba como a CashLink pode revolucionar a maneira como sua empresa administra pagamentos e conecta-se com seus clientes.</h6>
            <img
              src="../../../img/cashlink/04.png"
              alt="CashLink"
              className="cs-radius_10 w-100"
            />
            <h3>O que é a CashLink?</h3>
            <p>
              CashLink é uma Fintech focada em soluções de pagamentos inovadoras que permite que empresas gerenciem transações financeiras de forma simples e eficiente. Com ferramentas avançadas, é possível integrar diferentes formas de pagamento, acompanhar relatórios em tempo real e otimizar sua operação.
            </p>

            <h3>Por que escolher a CashLink?</h3>
            <p>
              a CashLink é ideal para empresas que desejam oferecer uma experiência de pagamento moderna e personalizada aos seus clientes. Ele combina tecnologia de ponta com facilidade de uso, tornando-se indispensável para negócios de qualquer porte.
            </p>

            <h3>Principais vantagens da CashLink</h3>
            <ul>
              <li>Integração com diversas formas de pagamento, incluindo cartões, Pix e boleto.</li>
              <li>Relatórios detalhados para acompanhamento financeiro.</li>
              <li>Redução de custos operacionais com gestão automatizada.</li>
              <li>Plataforma segura e confiável, protegendo as informações do seu negócio.</li>
            </ul>

            <h3>Como começar a usar?</h3>
            <p>
              Para começar a usar a CashLink, entre em contato com nosso suporte e solicite a ativação da plataforma para o seu negócio. Nossa equipe estará disponível para orientar você em cada etapa do processo.
            </p>

            <blockquote>
              <p>
                "Desde que começamos a usar a CashLink, nossa gestão de pagamentos se tornou mais simples e eficiente. Recomendamos para qualquer negócio que queira inovar."
              </p>
              <small>Marcos Oliveira, Empreendedor</small>
            </blockquote>

            <p>
              Ficou com dúvidas? Nossa equipe está pronta para ajudar você.
              <a
                href="https://wa.me/5561982217107?text=Ol%C3%A1%2C%20gostaria%20de%20tirar%20algumas%20d%C3%BAvidas."
                target="_blank"
                rel="noopener noreferrer"
              >
                <br></br><strong>Clique aqui para conversar conosco pelo WhatsApp.</strong>
              </a>
            </p>
            <div className="cs-height_60 cs-height_lg_60"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
