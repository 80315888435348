import { RouterProvider } from "react-router-dom";
import { routes } from "./routes/routes";
import { Analytics } from "@vercel/analytics/react"

function App() {
  return (
    <>
      <RouterProvider router={routes}> </RouterProvider>
      <Analytics/>
    </>
  );
}

export default App;
