import React, { useEffect } from "react";

export default function Preloader() {
  useEffect(() => {
    // Simula o carregamento e remove o preloader após 2 segundos
    const preloader = document.querySelector(".cs-preloader");
    const preloaderIn = document.querySelector(".cs-preloader_in");

    setTimeout(() => {
      if (preloaderIn) preloaderIn.style.display = "none";
      if (preloader) preloader.style.display = "none";
    }, 2000); // Tempo ajustável
  }, []);

  return (
    <div className="cs-preloader cs-white_bg cs-center">
      <div className="cs-preloader_in">
        <img src="../../../img/icone_logo-01.png" alt="Icon CashLink" />
      </div>
    </div>
  );
}
