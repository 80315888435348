import React, { useEffect } from 'react';

const Widget = () => {
  useEffect(() => {
    // Fetch the widget HTML content
    fetch('https://zaplinkcrm.com.br/api/user/widget?id=1aQJaGNdRl')
      .then(response => response.text())
      .then(htmlContent => {
        // Inject the HTML content into the widget container
        const container = document.getElementById('widget-container');
        container.innerHTML = htmlContent;

        // Modify all links to open in a new tab
        const links = container.getElementsByTagName('a');
        Array.from(links).forEach(link => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer'); // Security improvement
        });
      })
      .catch(error => console.error('Error fetching widget content:', error));
  }, []);

  return <div id="widget-container"></div>;
};

export default Widget;
