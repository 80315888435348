import React from "react";

export default function About() {
  return (
    <section id="about" className="cs-gradient_bg_1">
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="row align-items-center flex-column-reverse-lg">
          <div
            className="col-xl-6 wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="cs-left_full_width cs-space110">
              <div className="cs-left_sided_img">
                <img src="../../../img/cashlink/11.png" alt="CashLin POS" />
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="cs-height_0 cs-height_lg_40"></div>
            <div className="cs-seciton_heading cs-style1">
              <div className="cs-section_subtitle">SOBRE OS PLANOS</div>
              <div className="cs-height_10 cs-height_lg_10"></div>
              <h3 className="cs-section_title">
                Aceite pagamentos com segurança e tecnologia sem complicações{" "}
              </h3>
            </div>
            <div className="cs-height_20 cs-height_lg_20"></div>
            <p>
              Na CashLink, simplificamos a gestão do seu negócio. Com nossas máquinas de cartão e conta digital no mesmo app, você aceita pagamentos com segurança e agilidade. Débito, crédito ou Pix, oferecemos soluções práticas e confiáveis para ajudar seu negócio a crescer.
            </p>
            <div className="cs-height_15 cs-height_lg_15"></div>
            <div className="cs-list_1_wrap">
              <ul className="cs-list cs-style1 cs-mp0">
                <li>
                  <span className="cs-list_icon">
                    <img src="../../../img/icons/tick.svg" alt="Tick" />
                  </span>
                  <div className="cs-list_right">
                    <h3>Recebimento em um dia!</h3>
                    <p>
                      Venda de diversas formas e receba o valor em 1 dia na sua conta CashLink! <br></br>
                      Escolha o plano de taxas ideal para o seu negócio e acelere suas vendas.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="cs-list_icon">
                    <img src="../../../img/icons/tick.svg" alt="Tick" />
                  </span>
                  <div className="cs-list_right">
                    <h3>Taxas iguais para CPF ou CNPJ</h3>
                    <p>
                      Oferecemos taxas excepcionais tanto para CNPJ quanto para CPF.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="cs-list_icon">
                    <img src="../../../img/icons/tick.svg" alt="Tick" />
                  </span>
                  <div className="cs-list_right">
                    <h3>Sem Taxas escondidas ou Letras Miúdas</h3>
                    <p>
                      Não trabalhamos com taxas promocionais e nem valor mínimo de vendas,
                      também não trabalhamos com taxas escondidas em letras miúdas, somos 100% transparentes com nossos planos.
                    </p>
                  </div>
                </li>
                <li>
                  <span className="cs-list_icon">
                    <img src="../../../img/icons/tick.svg" alt="Tick" />
                  </span>
                  <div className="cs-list_right">
                    <h3>Menos custo, Mais lucro</h3>
                    <p>
                      Seu negócio gira com taxas personalizadas e você sabe exatamente tudo que está pagando.
                    </p>
                  </div>
                </li>
              </ul>
              <div
                className="wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                <img src="../../../img/cashlink/05.png" alt="About" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="cs-height_135 cs-height_lg_0"></div>
    </section>
  );
}
