import React from "react";

export default function Blog() {
  return (
    <div className="cs-modal" data-modal="details">
      <div className="cs-close_overlay"></div>
      <div className="cs-modal_in">
        <div className="cs-modal_container cs-white_bg">
          <button className="cs-close_modal cs-center cs-primary_bg">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7071 1.70711C12.0976 1.31658 12.0976 0.683417 11.7071 0.292893C11.3166 -0.0976311 10.6834 -0.0976311 10.2929 0.292893L11.7071 1.70711ZM0.292893 10.2929C-0.0976311 10.6834 -0.0976311 11.3166 0.292893 11.7071C0.683417 12.0976 1.31658 12.0976 1.70711 11.7071L0.292893 10.2929ZM1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM10.2929 11.7071C10.6834 12.0976 11.3166 12.0976 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L10.2929 11.7071ZM10.2929 0.292893L0.292893 10.2929L1.70711 11.7071L11.7071 1.70711L10.2929 0.292893ZM0.292893 1.70711L10.2929 11.7071L11.7071 10.2929L1.70711 0.292893L0.292893 1.70711Z"
                fill="white"
              />
            </svg>
          </button>
          <div className="cs-height_60 cs-height_lg_60"></div>
          <div className="cs-blog_details">
            <h2>Parceria CashLink e Player4 Studios: Benefícios Exclusivos para Clientes</h2>
            <h6>
              Clientes das maquininhas da CashLink agora podem aproveitar até 70% de desconto nos planos de assinatura da Player4 Studios!
            </h6>
            <img
              src="../../../img/parceria1.png"
              alt="Parceria CashLink e Player4 Studios"
              className="cs-radius_10 w-100"
            />
            <h3>Sobre a Player4 Studios</h3>
            <p>
              A Player4 Studios é referência em tecnologia e inovação, oferecendo produtos que transformam a gestão de negócios e o relacionamento com clientes. Conheça os produtos desta parceria:
            </p>
            <ul>
              <li>
                <strong>ZapLink Shop:</strong> Crie sua loja virtual no WhatsApp e impulsione suas vendas com integração de pagamentos e ferramentas exclusivas. 
                <a href="https://zaplinkshop.com.br" target="_blank" rel="noopener noreferrer"> Visite o site</a>.
              </li>
              <li>
                <strong>ZapLink CRM:</strong> Um CRM completo para gerenciar o relacionamento com clientes, focado em otimizar a comunicação via WhatsApp e melhorar o atendimento. 
                <a href="https://zaplinkcrm.com.br" target="_blank" rel="noopener noreferrer"> Visite o site</a>.
              </li>
              <li>
                <strong>LinkCRM PRO:</strong> Integre múltiplos canais de comunicação, automatize tarefas e tenha uma visão unificada dos seus clientes com o CRM mais avançado do mercado. 
                <a href="https://linkcrm.com.br" target="_blank" rel="noopener noreferrer"> Visite o site</a>.
              </li>
            </ul>

            <h3>Vantagens Exclusivas para Clientes CashLink</h3>
            <p>
              Ao utilizar as maquininhas da CashLink, você tem acesso a descontos de até 70% nos serviços da Player4 Studios. 
              Essa é uma oportunidade única para modernizar sua operação com o que há de melhor em tecnologia e gestão.
            </p>
            <ul>
              <li>Descontos em planos de assinatura.</li>
              <li>Acesso às soluções mais inovadoras para lojas virtuais e CRM.</li>
              <li>Atendimento dedicado e suporte exclusivo.</li>
            </ul>

            <h3>Como Solicitar o Seu Desconto?</h3>
            <p>
              É simples! Crie sua conta no serviço solicitado e entre em contato com a equipe da Player4 Studios pelo WhatsApp para solicitar seu benefício, informando o CPF ou CNPJ com e-mail cadastrado:
            </p>
            <p>
              <a
                href="https://wa.me/5561991080476?text=Ol%C3%A1%2C%20sou%20cliente%20CashLink%20e%20gostaria%20solicitar%20o%20meu%20desconto%20especial."
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui para falar com a Player4 Studios
              </a>
            </p>

            <blockquote>
              <p>
                "A parceria entre a CashLink e a Player4 Studios nos trouxe uma economia incrível e soluções que realmente fazem a diferença no dia a dia do nosso negócio.
                <br></br>E pra melhorar, tivemos todo suporte para digitalizar nossa empresa, vendo online com o ZapLink Shop, atendo com o ZapLink CRM e toda a configuração <br></br>
                da API foi feita por eles, tudo incrível, estou bastante satisfeito."
              </p>
              <small>Baltazar Madureira, Empresário</small>
            </blockquote>
            <div className="cs-height_60 cs-height_lg_60"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
